import React from 'react'
import styled from 'styled-components'
import { Tag } from 'components/tag/tag'
import { Title } from 'components/title/title'
import { Text, TextFonts } from 'components/text/text'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { DateText } from 'components/date-text/date-text'
import { mediaBreakpointUpSm, mediaBreakpointUpLg } from 'theme/breakpoints'
import { boxShadowRgbaWithHex, transition } from 'theme/utils'
import { SvgPhotoGallery } from 'components/svgs/svg-photo-gallery'
import { SvgPlayMedium } from 'components/svgs/svg-play-medium'
import { SvgPlaySmall } from 'components/svgs/svg-play-small'
import { NextLink } from 'components/next-link/next-link'

export enum NewsCardTypes {
	simple = 'simple',
	photoGallery = 'photoGallery',
	video = 'video',
}
export enum NewsCardSizes {
	small = 'small',
	medium = 'medium',
	large = 'large',
}

type NewsCardProps = {
	id: string | number
	url: string
	title: string
	category: {
		href: string
		label: string
	}
	timestamp: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
	type?: NewsCardTypes
	description?: string
	size?: NewsCardSizes
	isRelatedNews?: boolean
}

const NewsCardHeader = styled.header`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-top: 11px;
`

const NewsCardTitle = styled(Title)<{ $maxLines: number; $isVideo?: boolean; $isRelatedNews?: boolean }>`
	padding-top: 2px;
	margin: ${props => (props.$maxLines === 2 ? '7px 0 6px' : '3px 0 0')};
	font-size: 24px;
	line-height: 28px;
	letter-spacing: -0.1px;
	transition: ${transition('opacity')};

	${mediaBreakpointUpLg} {
		/* stylelint-disable-next-line */
		margin: ${props =>
			props.$isVideo && !props.$isRelatedNews ? '4px 0 0' : props.$maxLines === 2 ? '7px 0 0' : '6px 0 0'};
		font-size: ${props => (props.$isVideo && !props.$isRelatedNews ? '16px' : props.$maxLines === 2 ? '30px' : '24px')};
		line-height: ${props =>
			props.$isVideo && !props.$isRelatedNews ? '20px' : props.$maxLines === 2 ? '32px' : '28px'};
	}
`

const NewsCardDescription = styled(Text)`
	margin: -1px 0 0;
`

const NewsCardImageLink = styled(NextLink)`
	display: block;
	width: calc(100% + 30px);
	margin-right: -15px;
	margin-left: -15px;
	${props => boxShadowRgbaWithHex('0 1px 15px 0', props.theme.colors.black, 0.1)}
	${mediaBreakpointUpSm} {
		width: auto;
		margin-right: 0;
		margin-left: 0;
	}

	${mediaBreakpointUpLg} {
		overflow: hidden;
		border-radius: 2px;
	}

	&:hover {
		.image-placeholder::after {
			opacity: 0.4;
		}
	}
`

const NewsCardIconLink = styled(NextLink)<{ size: NewsCardSizes }>`
	position: absolute;
	top: ${props => (props.size === NewsCardSizes.large ? '12px' : '6px')};
	right: ${props => (props.size === NewsCardSizes.large ? '16px' : '-5px')};

	${mediaBreakpointUpSm} {
		right: ${props => (props.size === NewsCardSizes.large ? '16px' : '10px')};
	}
`

const NewsCardArticle = styled.article<{ $isVideo: boolean; $isRelatedNews?: boolean }>`
	.image-placeholder::after {
		position: absolute;
		inset: 0;
		pointer-events: none;
		content: ' ';
		/* stylelint-disable-next-line */
		background-color: ${props =>
			props.$isVideo && !props.$isRelatedNews ? props.theme.colors.black : props.theme.colors.nightBlue};
		border-radius: 2px;
		opacity: ${props => (props.$isVideo && !props.$isRelatedNews ? 0.2 : 0)};
		transition: ${transition('opacity')};
	}
`

const NewsCardArticleThumbnail = styled.div`
	position: relative;
`

const NewsCardTitleLink = styled(NextLink)`
	&:hover {
		text-decoration: none;

		${NewsCardTitle} {
			color: ${props => props.theme.text.secondary};
		}
	}
`

const NewsCardComponent = ({
	id,
	type = NewsCardTypes.simple,
	url,
	thumbnail,
	title,
	description = '',
	category,
	timestamp,
	size = NewsCardSizes.medium,
	isRelatedNews = false,
}: NewsCardProps) => {
	const isPhotoGallery = type === NewsCardTypes.photoGallery
	const isVideo = type === NewsCardTypes.video
	const isLarge = size === NewsCardSizes.large
	const isMedium = size === NewsCardSizes.medium
	const aspectRatio = isLarge ? '730/470' : '350/220'
	return (
		<NewsCardArticle $isVideo={isVideo} $isRelatedNews={isRelatedNews}>
			<NewsCardArticleThumbnail>
				<NewsCardImageLink href={url} passHref>
					<LazyImage {...thumbnail} aspectRatio={aspectRatio} />
				</NewsCardImageLink>
				{isPhotoGallery && (
					<NewsCardIconLink href={url} passHref size={size}>
						<SvgPhotoGallery suffix={`-${id}`} />
					</NewsCardIconLink>
				)}
				{isVideo && !isLarge && (
					<NewsCardIconLink href={url} passHref size={size}>
						{isMedium ? <SvgPlayMedium suffix={`-${id}`} /> : <SvgPlaySmall suffix={`-${id}`} />}
					</NewsCardIconLink>
				)}
			</NewsCardArticleThumbnail>
			<NewsCardHeader>
				<Tag href={category.href} transparent>
					{category.label}
				</Tag>
				<DateText timestamp={timestamp} />
			</NewsCardHeader>
			<NewsCardTitleLink href={url} passHref>
				<NewsCardTitle size={3} $maxLines={description ? 4 : 3} $isVideo={isVideo} $isRelatedNews={isRelatedNews}>
					{title}
				</NewsCardTitle>
			</NewsCardTitleLink>
			{description && (
				<NewsCardDescription font={TextFonts.secondary} maxLines={3}>
					{description}
				</NewsCardDescription>
			)}
		</NewsCardArticle>
	)
}

export const NewsCard = React.memo(NewsCardComponent)
